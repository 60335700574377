import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NamesPage} from './names/names.page';
import {NamesModule} from './names/names.module';
import {GamePage} from './game/game.page';

const routes: Routes = [
    {path: '', component: NamesPage},
    {path: 'names', component: NamesPage},
    {path: 'game', component: GamePage},
    {path: '**', component: NamesPage},

];

@NgModule({
    declarations: [
    ],
    imports: [
        NamesModule,
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
