import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxWheelModule} from 'ngx-wheel';
import {MessagesService} from './services/messages/messages.service';
import {HttpClient, HttpClientModule, HttpHandler} from '@angular/common/http';
import {Tab1Page} from './tab1/tab1.page';
import {GamePage} from './game/game.page';
import {Tab4Page} from './tab4/tab4.page';
import {Tab5Page} from './tab5/tab5.page';
import {NamesModule} from './names/names.module';
import {NamesPage} from './names/names.page';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {GameModule} from './game/game.module';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import {FormsModule} from '@angular/forms';

const appRoutes: Routes = [
    { path: '', component: NamesPage },
    { path: 'names', component: NamesPage },
    { path: 'game', component: GamePage },
    { path: '**', component: NamesPage },
    // { path: 'tab1', component: Tab1Page },
    // { path: 'tab4', component: Tab4Page },
    // { path: 'tab5', component: Tab5Page }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [],
    imports: [
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxWheelModule,
        NamesModule,
        GameModule,
        FormsModule,
        RouterModule.forRoot(
            appRoutes
        ),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        MessagesService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide : LocationStrategy , useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
