import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Globals } from "../app.globals";
import { NavController } from "@ionic/angular";

@Component({
  selector: "app-game",
  templateUrl: "game.page.html",
  styleUrls: ["game.page.scss"],
  animations: [
    trigger("EnterLeave", [
      state("flyIn", style({ transform: "translateX(-50%)" })),
      transition(":enter", [
        style({ transform: "translateX(-180%)" }),
        animate("0.3s ease-in"),
      ]),
      transition(":leave", [
        animate("0.3s ease-out", style({ transform: "translateX(50%)" })),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class GamePage implements OnInit {
  constructor(private readonly navCtrl: NavController) {}

  public message: string;
  private followUpMsg = null;
  public category = 1;
  public newMessage = true;
  public color: string;
  private messages: string[];

  private players = JSON.parse(localStorage.getItem("players"));

  ngOnInit() {
    document.title = "Betere Picolo";
  }

  ionViewWillEnter() {
    this.followUpMsg = null;
    this.players = JSON.parse(localStorage.getItem("players"));
    if (this.players != null && this.players.length > 0) {
      this.nextMessage();
    } else {
      this.navToNames();
    }
  }

  @HostListener("click", ["$event.target"])
  onClick() {
    this.nextMessage();
    this.newMessage = false;
    setTimeout(() => {
      this.newMessage = true;
    }, 300);
  }

  private nextMessage() {
    this.messages = JSON.parse(localStorage.getItem("messages"));
    if (this.messages.length > 0 || this.followUpMsg) {
      this.players = JSON.parse(localStorage.getItem("players"));
      let randomMsg = Math.floor(Math.random() * this.messages.length);
      let message;
      if (this.followUpMsg != null) {
        message = this.followUpMsg;
        randomMsg = null;
        this.followUpMsg = null;
      } else {
        message = this.messages[randomMsg];
      }
      message = this.setAndRemoveCategory(message);
      message = this.checkForFollowUpMessage(message);
      message = this.insertPlayersInMessage(message, this.players);
      this.message = this.insertRandomDrinkAmount(message);
      this.color = Globals.colorList[this.category];
      if (randomMsg != null) {
        this.messages.splice(randomMsg, 1);
        localStorage.setItem("messages", JSON.stringify(this.messages));
      }
    } else {
      this.message = "";
      this.navToNames();
    }
  }

  private insertPlayersInMessage(message: string, players: string[]): string {
    let count = 0;
    let tempMsg = message;
    if (tempMsg === undefined) {
      tempMsg = "";
    }
    while (tempMsg.indexOf("p") !== -1) {
      const index = tempMsg.indexOf("p");
      if (index + 1 < tempMsg.length) {
        const char = tempMsg.charAt(index + 1);
        if (char >= "0" && char <= "9") {
          count++;
        }
      }
      tempMsg = this.setCharAt(tempMsg, index, "-");
    }
    for (let i = 0; i < count; i++) {
      const randomPlayer = Math.floor(Math.random() * players.length);
      const playerCount = i + 1;
      const playerPos = "p" + playerCount;
      if (this.category !== 3) {
        message = message
          .split(playerPos)
          .join('<b class="name">' + players[randomPlayer] + "</b>");
      } else if (this.category === 3) {
        message = message
          .split(playerPos)
          .join('<b class="name-nasty">' + players[randomPlayer] + "</b>");
      }
      const player = players.indexOf(players[randomPlayer], 0);
      if (player > -1) {
        players.splice(player, 1);
      }
    }
    return message;
  }

  private insertRandomDrinkAmount(message: string) {
    while (message.indexOf("[random]") !== -1) {
      const i = message.indexOf("[random]");
      const r = Math.floor(Math.random() * 8) + 2;
      if (r < 9) {
        message =
          message.substr(0, i) +
          r +
          " slokken" +
          message.substr(i + 8, message.length);
      } else {
        message =
          message.substr(0, i) +
          "een shotje" +
          message.substr(i + 8, message.length);
      }
    }
    return message;
  }

  private checkForFollowUpMessage(message: string): string {
    if (message.indexOf(" F ") > 0) {
      const firstMessage = message.substr(0, message.indexOf(" F "));
      this.followUpMsg = message.substr(
        message.indexOf(" F ") + 3,
        message.length
      );
      return firstMessage;
    }
    return message;
  }

  private setAndRemoveCategory(message: string): string {
    if (
      message.charAt(message.length - 1) >= "0" &&
      message.charAt(message.length - 1) <= "9" &&
      message.charAt(message.length - 2) === "c"
    ) {
      this.category = Number(message.charAt(message.length - 1));
      return message.substr(0, message.length - 3);
    }
    return message;
  }

  private setCharAt(str: string, index: number, char: string): string {
    return str.substr(0, index) + char + str.substr(index + 1);
  }

  public navToNames() {
    this.navCtrl.navigateForward("names", { animated: true });
  }
}
