import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NamesPage} from './names.page';
import {MessagesService} from '../services/messages/messages.service';
import {HttpClient, HttpClientModule, HttpHandler} from '@angular/common/http';
import {AppComponent} from '../app.component';

@NgModule({
    imports: [
        HttpClientModule,
        IonicModule,
        CommonModule,
        FormsModule,
        RouterModule.forChild([{path: '', component: NamesPage}])
    ],
    declarations: [NamesPage
    ],
    providers: [
        MessagesService,
        AppComponent
    ]
})
export class NamesModule {
}
