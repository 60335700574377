import { Component } from "@angular/core";

import { MenuController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  public categories = [
    { value: "Casual", isChecked: true, id: 1 },
    { value: "Go Crazy", isChecked: false, id: 2 },
    { value: "Go Nasty", isChecked: false, id: 3 },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private readonly menu: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  public closeMenu() {
    this.menu.close();
  }
}
