import {Injectable} from '@angular/core';
import MessagesJSON from '../../../assets/messages.json';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    public static loadMessages(categories: any[], nPlayers: number): string[] {
        let messages = [];
        messages = messages.concat(this.filterNPlayers(MessagesJSON, nPlayers));
        messages = this.filterCategories(categories, messages);
        return this.getRandomMessages(messages);
    }

    private static filterNPlayers(messages: string[], nPlayers): string[] {
        const filteredMessages = [];
        for (const message of messages) {
            if (message.charAt(message.length - 1) >= '0' && message.charAt(message.length - 1) <= '9') {
                let n = message.charAt(message.length - 1);
                if (message.charAt(message.length - 2) >= '0' && message.charAt(message.length - 2) <= '9') {
                    n = message.charAt(message.length - 2) + message.charAt(message.length - 1);
                    if (n <= nPlayers) {
                        filteredMessages.push(message.substr(0, message.length - 3));
                    }
                } else if (n <= nPlayers) {
                    filteredMessages.push(message.substr(0, message.length - 2));
                }
            }
        }
        return filteredMessages;
    }

    private static filterCategories(categories: any[], messages: string[]): string[] {
        const filteredMessages: string[] = [];
        for (const message of messages) {
            for (const category of categories) {
                if (category.isChecked === true && category.id === parseInt(message.charAt(message.length - 1), 10)) {
                    filteredMessages.push(message);
                }
            }
        }
        return filteredMessages;
    }

    private static getRandomInts(nOfMessages, messagesLength) {
        const ints = [];
        while (ints.length < nOfMessages) {
            const r = Math.floor(Math.random() * messagesLength);
            if (!(ints.indexOf(r) > -1)) {
                ints.push(r);
            }
        }
        return ints;
    }

    private static getRandomMessages(messages) {
        const randomMessages = [];
        let nrOfMessages = messages.length;
        if (messages.length >= 25) {
            nrOfMessages = 25;
        }
        const randomInts = this.getRandomInts(nrOfMessages, messages.length);
        randomInts.forEach(int => {
            randomMessages.push(messages[int]);
        });
        return randomMessages;
    }
}
