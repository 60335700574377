import {Component, OnInit} from '@angular/core';
import {MessagesService} from '../services/messages/messages.service';
import {MenuController, NavController} from '@ionic/angular';
import {AppComponent} from '../app.component';

@Component({
    selector: 'app-names',
    templateUrl: 'names.page.html',
    styleUrls: ['names.page.scss']
})
export class NamesPage implements OnInit {

    public nPlayers = 15;
    public nPlayersArr = [];
    public players = [];
    public background = '../../assets/test.png';

    constructor(private readonly navCtrl: NavController, private readonly menu: MenuController, private readonly appComponent: AppComponent) {
    }

    ngOnInit(): void {
        document.title = 'Betere Picolo';
        this.players = JSON.parse(localStorage.getItem('players'));
        if (this.players == null) {
            this.players = [];
        }
        for (let i = 1; i <= this.nPlayers; i++) {
            this.nPlayersArr.push(i);
        }
    }

    public addPlayers(): void {
        this.players = [];
        for (let i = 1; i <= this.nPlayers; i++) {
            const inputValue = (document.getElementById('player' + i) as HTMLInputElement).value;
            if (inputValue !== '' && inputValue != null) {
                this.players.push(inputValue);
            }
        }
        localStorage.setItem('players', JSON.stringify(this.players));
    }

    public loadMessages() {
        localStorage.setItem('messages', JSON.stringify(MessagesService.loadMessages(this.appComponent.categories, this.players.length)));
    }

    public navToGame() {
        const messages: string[] = JSON.parse(localStorage.getItem('messages'));
        if (this.players.length > 0 && messages.length > 0) {
            this.navCtrl.navigateBack('game', {animated: true});
        }
    }

    public openMenu() {
        this.menu.open();
    }
}
